import React, { useContext } from 'react'
import PhoneReveal from '@global/PhoneReveal'
import { UserContext } from '@context/UserContext'
import ShareButton from '@global/ShareButton'
import FavouriteHeart from '@global/FavouriteHeart'
import IconDownload from '@icons/Download'
import vCardCreator from '@lib/helpers/vcard'
import * as S from './ContactDetails.styled'
import ContactAddress from '@components/Offices/OfficeProfile/ContactDetails/ContactAddress'
import { googleLocationLink } from '@lib/helpers'

const ContactDetails = ({
  locationName,
  address,
  suburb,
  postcode,
  state,
  phone,
  fax,
  directionUrl,
  officeId,
  officeName,
  shareObject,
  email,
  extraLocations
}) => {
  const { token } = useContext(UserContext)

  return (
    <S.ContactDetails>
      <h4>{locationName}</h4>
      <ContactAddress
        phone={phone}
        fax={fax}
        officeName={extraLocations && extraLocations.length > 0 ? officeName : null}
        address={address}
        suburb={suburb}
        state={state}
        postcode={postcode}
      />
      {directionUrl && address && (
        <S.GoogleMapLink href={directionUrl} target='_blank'>
          View on Google Maps
        </S.GoogleMapLink>
      )}

      <S.ContactDetailsActions>
        <ShareButton
          heading='Share this Office'
          shareInfo={shareObject}
          modalPosition='right'
        />
        <S.SaveContact
          onClick={() => {
            vCardCreator.makeVCard({
              name: `Barry Plant ${locationName}`,
              tel: phone,
              address: `${address}, ${suburb} ${state} ${postcode}`,
              email,
            })
          }}
        >
          <IconDownload /> Save Contact
        </S.SaveContact>
      </S.ContactDetailsActions>

      {extraLocations && extraLocations.map((location, index) => {
        const { latitude, longitude } = location
        const directionExtraUrl = googleLocationLink({latitude, longitude})
        return (
          <S.ExtraLocations key={index}>
            <ContactAddress
              phone={location?.phone}
              fax={location?.fax}
              officeName={location?.location_name}
              address={location?.address}
              suburb={location?.suburb}
              state={location?.state}
              postcode={location?.postcode}
            />

            {directionExtraUrl && location?.address && (
              <S.GoogleMapLink href={directionExtraUrl} target='_blank'>
                View on Google Maps
              </S.GoogleMapLink>
            )}

            <S.ContactDetailsActions>
              <ShareButton
                heading='Share this Office'
                shareInfo={shareObject}
                modalPosition='right'
              />
              <S.SaveContact
                onClick={() => {
                  vCardCreator.makeVCard({
                    name: `Barry Plant ${location?.location_name}`,
                    tel: location?.phone,
                    address: `${location?.address}, ${location?.suburb} ${location?.state} ${location?.postcode}`,
                    email
                  })
                }}
              >
                <IconDownload /> Save Contact
              </S.SaveContact>
            </S.ContactDetailsActions>
          </S.ExtraLocations>
        )
      })}

      {token && (
        <S.WishlistWrap>
          <FavouriteHeart
            key='wishlist'
            type='office'
            id={officeId}
            text
            inline
            label='Add to Favourites'
            label_added='Remove from Favourites'
          />
        </S.WishlistWrap>
      )}
    </S.ContactDetails>
  )
}

export default ContactDetails
